<template>
  <title-wrapper title="Podaj email aby zresetować hasło">
    <v-container class="white pa-10">
      <v-form v-model="isFormValid" ref="form">
        <v-row class="mb-4">
          <h2 class="text">Email</h2>
        </v-row>
        <v-row>
          <v-col class="ma-0 pa-0" cols="12" sm="6" md="4">
            <v-text-field
              v-model="email"
              elevation="0"
              label="Email"
              :rules="validator.emailRules"
              outlined
              solo
            ></v-text-field>
            <primary-button :onClick="sendResetLink">Zresetuj hasło</primary-button>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </title-wrapper>
</template>
<script>
import PrimaryButton from '../../components/buttons/PrimaryButton.vue';
import TitleWrapper from '../../components/TitleWrapper.vue';
import AuthService from '../../services/AuthService';
import { validators } from '../../validators/Validators';

export default {
  components: { TitleWrapper, PrimaryButton },
  data() {
    return {
      loading: false,
      isFormValid: false,
      email: '',
    };
  },
  methods: {
    async sendResetLink() {
      this.loading = true;
      this.$refs.form.validate();
      if (this.isFormValid) {
        try {
          await AuthService.sendPasswordLink(this.email);
          this.$router.push({ name: 'PasswordMailSent' });
        } catch (error) {
          console.error(error);
        } finally {
          this.loading = false;
        }
      }
    },
  },
  computed: {
    validator() {
      return validators;
    },
  },
};
</script>
<style lang="scss" scoped></style>
